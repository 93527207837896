import BannerSlider from '@/components/BannerSlider'
import { CompetitionsEndSoon } from '@/components/Competition'
import WebInfo from '@/components/WebInfo'
import WinnerCarousel from '@/components/WinnerCarousel'
import { useLanguage } from '@/hooks/Contexts'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, Fragment, PropsWithChildren, useMemo } from 'react'
import { AppLayoutProps } from './props'

export const AppLayout: FC<PropsWithChildren<AppLayoutProps>> = ({ children, title, titleOuterCls, titleInnerCls, outerCls, innerCls, filter, showBanner }) => {
  const { pathname } = useRouter()
  const { navigation: navStr } = useLanguage()
  const titleStr = useMemo(() => (title ? (navStr[title] ?? title) + ' - ' + navStr.app_name : navStr.app_name), [navStr, title])
  return (
    <Fragment>
      <Head>
        <title>{titleStr}</title>
        {pathname == '/competitions/[shortName]' ? null : (
          <>
            <meta name='title' content={titleStr} key='title' />
            <meta property='og:title' content={titleStr} key='og:title' />
            <meta name='twitter:title' content={titleStr} key='twitter:title' />
          </>
        )}
      </Head>

      {showBanner ? <BannerSlider /> : null}

      {title || filter ? (
        <div className={`animate-fade-in-down bg-white/100 shadow-[3px_4px_15px_0px_#0000000D]${showBanner ? '' : ' mt-16 md:mt-20'} ${titleOuterCls || ''}`}>
          <div className={`container-white ${titleInnerCls || ''}`}>
            <span className='text-xl font-medium capitalize md:text-2xl'>{title ? navStr[title] || title.replaceAll('_', '') : null}</span>
            {filter}
          </div>
        </div>
      ) : null}

      {children ? (
        <div className={`fluid-gray animate-fade-in-down${title || filter || showBanner ? '' : ' mt-16 md:mt-20'} ${outerCls || ''}`}>
          <div className={`container-gray ${innerCls || ''}`}>{children}</div>
        </div>
      ) : null}

      {showBanner ? (
        <Fragment>
          <WebInfo />
          <WinnerCarousel />
          <CompetitionsEndSoon />
        </Fragment>
      ) : null}
    </Fragment>
  )
}
export default AppLayout
