import { useLanguage } from '@/hooks/Contexts'
import { useTick } from '@/hooks/Countdown'
import { svgs } from '@/utils/Images'
import { Action0 } from '@/utils/Types'
import { FC } from 'react'

export const CompetitionCountdown: FC<{ endDate?: string; onFinish?: Action0; extraCls?: string; light?: boolean }> = ({
  endDate,
  onFinish,
  extraCls,
  light,
}) => {
  const {
    competitions: { ends_in, countdown_units },
  } = useLanguage()

  const { countDown } = useTick(['00', '00', '00', '00'], ['days', 'hours', 'minutes', 'seconds'], endDate, null, null, onFinish) // 15 minutes

  const blockCls = `flex items-center justify-center rounded text-center ${
    light ? 'w-5 bg-white/100 text-black xs:w-7 xs:text-lg sm:w-10 sm:text-xl lg:w-12' : 'w-6 bg-stone-600 text-white/100'
  }`

  return (
    <div
      className={`flex items-start transition-all duration-300 ${
        light ? 'md:min-w-max md:flex-col md:items-center md:justify-self-end' : 'w-full justify-between sm:justify-start sm:gap-x-2'
      } ${extraCls || ''}`}
    >
      {/* ends in */}
      <div className={`flex items-center whitespace-nowrap ${light ? 'font-semibold text-cyan-v1 xs:h-6 xs:text-lg sm:h-9 sm:text-2xl md:h-auto' : 'h-6'}`}>
        <svg className={`mr-1 text-cyan-v1 ${light ? 'h-4 w-4 xs:h-5 xs:w-5 sm:h-6 sm:w-6' : 'h-4 w-4'}`} fill='none' viewBox='0 0 21 23'>
          {svgs.clock}
        </svg>
        {ends_in}
      </div>

      {/* count down grid */}
      <div className={`${light ? 'ml-2 md:ml-0 md:mt-2' : ''}`}>
        <div className={`grid grid-cols-4 justify-items-center text-center ${light ? 'gap-x-2 sm:h-9' : 'h-6 gap-x-[7px]'}`}>
          {countDown.map((cd, i) => (
            <div key={'cd-' + i} className={`relative flex items-stretch gap-x-1`}>
              {i > 0 ? <div className={`absolute ${light ? '-left-1.5 text-white/100 sm:top-1' : '-left-[5px] top-1 text-black'}`}>:</div> : null}
              <div className={blockCls}>{cd[0]}</div>
              <div className={blockCls}>{cd[1]}</div>
            </div>
          ))}
        </div>
        <div className={`grid w-full grid-cols-4 justify-items-center text-center xs:gap-x-3 ${light ? 'mt-1 gap-x-2 md:mt-2' : 'gap-x-1.5'}`}>
          {[0, 1, 2, 3].map(i => (
            <div key={'cd-unit-' + i} className={`capitalize ${light ? 'text-amber-v1' : 'text-xs text-stone-500'}`}>
              {countdown_units[i]}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CompetitionCountdown
